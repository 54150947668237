@import 'variables';

.opportunities {
  padding: 120px 0 90px;
  .title {
    width: 32%;
    position: relative;
    .bg{
      width: 335px;
      height: 300px;
      background-image: url("../img/opportunities_bg.png");
      background-repeat: no-repeat;
      position: absolute;
      top: 10px;
      left:-70px;
      z-index: -1;
    }

    h2 {
      width: 300px;
      margin-top: 80px;
    }
  }

  .lists {
    width: 68%;
  .image{
    width: 150px;
    height: 150px;
    margin-bottom: -20px;
    margin-left: -20px;
  }
    .left-list, .right-list {
      width: 50%;
    }
    .left-list{
      padding-right: 30px;
    }

    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 29px;
      color: #000000;
      margin: 30px 0 40px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-family: Lato,  'sans serif';
        font-weight: normal;
      }
    }

    .list {
      li:before {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        top: 8px;
        left: -27px;
        background-image: url("../img/green-marker.svg");
        background-repeat: no-repeat;
      }
    }
  }
}

@media (max-width: 1366px) {
  .opportunities {
    padding: 100px 0 60px;
  }
}

@media (max-width: 1170px) {
  .opportunities {
     .fx-container-wr{
       display: block;
       background-image: url("../img/opportunities_bg_1170.svg");
       background-repeat: no-repeat;
       background-position: top -50px left 30%;
     }
    .title{
      width: 100%;
      h2{
        width: 100%;
        margin: 0 0 20px;
      }
    }

      .bg{
        display: none;
      }

     .lists{
       width: 100%;
       .image, .title{
         display: inline-block;
         margin: 0;
         vertical-align: middle;
         width: auto;
       }
       .title{
         margin-top: 40px;
       }
     }
    .list {
      margin-top: 25px;
    }

  }
}

@media (max-width: 1170px) {
  .opportunities {
    padding: 75px 0 50px;
  }
}

@media (max-width: 780px) {
  .opportunities {
    padding: 60px 0 40px;

  }

}

@media (max-width: 580px) {
  .opportunities {
    padding: 50px 0 40px;
    .lists {
      .lists-item{
        width: 100%;
        padding-right: 0;
      }
    }
  }
}