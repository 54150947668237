@import 'variables';

.title-block{
  height: 659px;
  position: relative;
  .substrate{
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;

   &_left{
     height: 100%;
     width: 57%;
     background-color: $bg-color;
     background-image: url("../img/dotted-arrow.svg");
     background-repeat: no-repeat;
     background-position: right 17px bottom 28px;
   }
    &_right{
      width: 43%;
      height: calc(100% + 4px);
      background-image: url("../img/title-block_bg.png");
      background-size: auto 100% ;
    }
  }
  .wrapper{
    position: relative;
    z-index: 5;
    .get-loan{
      position: absolute;
      width: 550px;
      height: 420px;
      left: 50%;
      top:119px;
      background-image: url("../img/get-loan_bg.png");
      background-size: 100%;
      box-shadow: 30px 30px 20px rgba(0, 0, 0, 0.2), 0px 0px 10px rgba(0, 0, 0, 0.03);
      img{
        width: 156px;
        height: 107px;
        position: absolute;
        top:90px;
        right: 113px;
      }
    }
  }
  .title-block_content{
    width: 50%;
    padding: 125px 50px 0 0;

    .text{
      font-family: Montserrat;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.7;
      margin: 40px 0 50px;
      max-width: 470px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-family: Lato,  'sans serif';
        font-weight: normal;
      }
    }
  }
  .actions{
    display: flex;
    .btn-blue{
      margin-right: 20px;
    }
    .btn{
     width: 250px;
    }
  }
}


@media (max-width: 1366px) {

  .title-block{
    height: 599px;
    .title-block_content{
      padding-top: 100px;
    }
    .wrapper{
      .get-loan{
        top: 59px;
        width: 458px;
        height: 420px;
        background-size: auto 100%;
      }
    }
  }

}

@media (max-width: 1240px) {
  .title-block{
    .actions{
      flex-wrap: wrap;
      .btn-blue{
        margin-bottom: 20px;
      }
    }
  }
}


@media (max-width: 1170px) {

  .title-block{
    height: 599px;
    .wrapper{
      .get-loan{
        width: 430px;
        height: 420px;
      }
    }
    .title-block_content {
      .text {
        font-size: 16px;
        margin: 30px 0 40px;
      }
    }
  }

}

@media (max-width: 992px) {
  .title-block{
   .substrate{
     &_left{
       background-image: none;
     }
   }
    .title-block_content{
      padding-top: 90px;
    }
    height: 480px;
    .wrapper{
      .get-loan{
        width: 370px;
        height: 360px;
      }
    }
    .title-block_content {
      padding-top: 70px;
      .text {
        font-size: 15px;
        margin: 25px 0 30px;
      }
    }
  }

}

@media (max-width: 810px) {
  .title-block{
    padding: 60px 0 50px;
    height: auto;
     .get-loan{
      display: none;
    }
   .substrate{
     background-image: url("../img/get-loan_bg.png");
     background-position: top center;
     background-repeat: no-repeat;
     background-size: cover;
     &_left, &_right{
       background: rgba(28,28,95,.61);;
     }
   }
    .title-block_content{
      padding: 0;
      width: 100%;
      max-width: 450px;
      color:#fff;
      h1{
        color:#fff;
      }
    }
    .actions{
      .btn-blue-border{
        color:rgba(255,255,255,0.9);
        border-color: rgba(255,255,255,0.9);
        &:hover{
          border-color: $blue-color;
          color:#fff;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .title-block{
    .btn {
      &.btn-blue, &.btn-blue-border{
       width: 100%;
        margin-right: 0;
      }
    }
  }

}