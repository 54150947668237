.hs-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hs-richtext {
  margin-bottom: 25px;

  p {
    color: #153d69 !important;
    font-size: 12px !important;
    margin-bottom: 0 !important;
  }

  a {
    color: #302925 !important;
    text-decoration: underline !important;
  }

}


@keyframes flipy {
  from {
    top: 0;
  }
  to {
    top: -15px;
  }
}

.hs-form-field {
  width: 47%;
  margin-bottom: 20px;
  padding-bottom: 17px;
  position: relative;

  &.hs-fieldtype-textarea {
    width: 100%;

  }

  & > label {
    display: inline-block;
    font-family: Montserrat, "sans serif" !important;
    font-size: 17px;
    color: $light-text-color;
    position: absolute;
    left: 0;
    top: 12px;
    font-weight: 400;

    &.raised {
      top: -15px;
      color: #b5b9be;
      font-size: 14px;
      animation: flipy 0.2s ease;
    }
  }

}

.hs-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid $light-text-color;
  background-color: transparent;
  padding: 9px 0;
  color: $dark-text-color;
  font-size: 17px;
  font-family: Montserrat, "sans serif" !important;

  &.invalid {
    border-color: #f50a18;
  }

  &:focus {
    outline: none;
    border-color: $dark-text-color;
  }
}

.hs-form .input .invalid .error {
  color: #f50a18;
  border: 1px solid #f50a18;
}

.hs-error-msg {
  font-size: 12px !important;
  color: #f50a18 !important;
  font-family: Montserrat, "sans serif";
  font-weight: 400;
  left: 0;
  bottom: 0;
}

.hs-error-msgs {
  padding-left: 0;

  li:not(:first-child) {
    display: none;
  }
}

.hs-form ul {
  list-style: none;
  position: absolute;
}

.hs-submit {
  width: 100%;
  margin-top: 20px;
}

.hs-button {
  text-align: center;
  height: 50px;
  line-height: 40px;
  text-decoration: none;
  border: 2px solid #0064fe;
  border-radius: 46px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  font-family: Montserrat;
  transition: background-color .2s, color .2s, opacity .2s;
  letter-spacing: 1px;
  padding: 0 20px;
  background-color: #0064fe;
  width: 200px;

  &:hover {
    opacity: .8;
  }

  &:focus, &:active {
    outline: none;
  }
}

.hs_error_rollup {
  display: none;
}

.submitted-message {
  padding: 68px 82px;
  text-align: center;
  border: 1px solid #c5c5c5;
  margin-top: 50px;

  p {
    font-size: 35px !important;
    color: #1F2022 !important;
    line-height: 1.3 !important;
  }
}

.s-hidden {
  visibility: hidden;
  padding-right: 10px;
}


.hs_attach_file {
  position: relative;
  width: 100% !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;

  label {
    position: static;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    color: #000;
    font-weight: 700;
    letter-spacing: 1px;

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .label-image {
    width: 15px;
    height: 16px;
    margin-left: 8px;
    background: url('../img/icon-attach.svg') no-repeat;
    background-size: cover;
  }

  legend {
    display: none !important;
    width: auto !important;
    white-space: nowrap;
    font-size: 12px;
    background-color: #eee;
    padding: 2px 5px;
    border-radius: 3px;
    position: absolute;
    left: 0;
    bottom: 3px;

    &.visible {
      display: inline-block !important;
    }

  }

  .question-image {
    margin-left: 10px;
    vertical-align: middle;

    svg {
      fill: #a5abb5;
      vertical-align: middle;
    }

    &:hover {
      svg {
        fill: $green-color;
      }
    }
  }
}


.file-upload {
  margin-top: -5px;
  position: relative;

  &__input {
    display: flex;
    align-items: center;

    label {
      font-size: 16px;
      line-height: 155%;
      color: #1F2022;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin: 0 7px;
      }
    }
  }

  &__file-name {
    height: 25px;
    margin: 0 0 6px;
    font-size: 12px;
    color: #153d69;
    padding-top: 5px;

    .file-size {
      color: #c4cbdb;
    }

    .delete {
      display: inline-block;
      font-size: 22px;
      transform: rotate(45deg);
      cursor: pointer;
      vertical-align: middle;
      color: #b6b6b6;
    }
  }
}

@media (max-width: 750px) {

  .hs-form {
    padding-bottom: 25px;
  }
  .hs-form-field {
    width: 100%;
  }

  .submitted-message {
    padding: 40px;

    p {
      font-size: 22px !important;
    }
  }
}


@media (max-width: 480px) {
  .hs-button {
    width: 100%;
  }
}






