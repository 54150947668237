$green-color: #058F1A;
$black-color: #2E2C2D;
$blue-color: #0064FE;
$bg-color: #FAFAFA;
$footer-color: #000;
$yellow-color: #fcc000;
$red-color: #f54f4f;

$light-text-color: #afafaf;
$dark-text-color: #262626;
$error-color: #e63900;
$light-gray-color: #cecece;
$placeholder-color: #9f9f9f;
$box-shadow: 3px 4px 15px -1px rgba(0, 0, 0, 0.26);
$list-bg: #ebf5f6;
