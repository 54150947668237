@import 'variables';

.video-player{

  padding: 70px 30px;

  .video-player_wrapper{
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }
  &.hidden{
    display: none;
  }
 .video-js{
   position: relative;
   width: 100%;
   min-width: 300px;
   min-height: 150px;
   height: auto;
   .vjs-tech {
     height: auto;
     position: static;
   }
 }
  video{
    width: 100%;
  }
}

