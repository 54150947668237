@import 'variables';

.privacy-policy{
  padding: 100px 0;
  .date{
    display: block;
    margin: 30px 0;
    font-size: 18px;
  }
  p{
    margin-bottom: 15px;
  }
  a{
    color: $blue-color;
    text-decoration: underline;
  }

  h3{
    font-size: 18px;
  }
}

@media (max-width: 1366px) {
  .privacy-policy {
    padding: 70px 0;
  }
}

@media (max-width: 580px) {
  .privacy-policy {
    padding: 40px 0;
  }
}