@import 'variables';

.contribution{
  padding: 100px 0 90px;
  position: relative;
  .bg{
    width: 50%;
    height: 100%;
    position: absolute;
    top:0;
    right: 0;
    background-image: url("../img/plane.svg");
    background-repeat: no-repeat;
    background-position:top -30px center;
    background-size: 80%;
    opacity: 0.8;
    z-index: -1;
  }
  .left-block{
    width: 52%;

    h2{
      padding-right: 120px;
    }
    .text{
      font-size: 18px;
      line-height: 32px;
      margin: 40px 0;
      &.text-privacy{
        margin: 30px 0 0;
        font-size: 15px;
        a{
          color: $blue-color;
          text-decoration: underline;
        }
      }
    }
    .btn{
      width: 220px;
    }
  }
  .right-block{
    width: 48%;
   display: flex;
    align-items: flex-end;
    padding-left: 80px;
    font-size: 15px;
    line-height: 32px;
    a{
      color: #0064fe;
      font-family: Montserrat;
      font-weight: 600;
    }
  }
}

@media (max-width: 1366px) {
  .contribution{
    .left-block{
      width: 65%;
      h2{
        padding-right: 0;
      }
    }
    .right-block{
      width: 35%;
      background-position: center;
      background-size: 150%;
    }
  }
}

@media (max-width: 1170px) {
  .contribution{
    padding: 65px 0;
    .bg{
      background-position:top -30px center;
    }
    .left-block{
      .text{
        font-size: 16px;
        line-height: 29px;
        margin: 20px 0 30px;
      }
    }
  }
}

@media (max-width: 930px) {
  .contribution{
    .bg{
      background: none;
    }
    .fx-container{
      flex-wrap: wrap;
    }
   .left-block, .right-block{
     width: 100%;
   }
    .right-block{
      padding: 20px 0 0;
    }
  }
}

@media (max-width: 780px) {
  .contribution{
    padding: 45px 0;
  }

}

@media (max-width: 450px) {
  .contribution{

    .left-block{
      .btn{
        width: 100%;
      }
    }
  }
}