@import 'variables';

.en{
  .platform-hes {
    h2{
      max-width: 670px;
    }
  }
}


.platform-hes {
  padding: 100px 0 20px;
  background-color: $bg-color;

  h2 {
    margin-bottom: 30px;
  }

  .platform-hes_items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 90px;
  }

  .platform-hes_item {
    display: flex;
    width: 25%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 80px;
    padding-right: 15px;

    .img {
      margin-right: 20px;
    }

    .content {
      max-width: 200px;
    }

    .text-16 {
      line-height: 25px;

    }

    .clock {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 12px;
      color: $green-color;
      margin-bottom: 7px;

      img {
        margin-right: 3px;
      }

      img, span {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .platform-hes {
    .platform-hes_item {
      .clock {
        font-family: Lato, 'sans serif';
        font-weight: normal;
      }
    }
  }
}

@media (max-width: 1170px) {

  .en{
    .platform-hes {
      h2{
        max-width: 500px;
      }
    }
  }

  .platform-hes {
    padding: 85px 0 20px;

    .platform-hes_items {
      margin-top: 35px;
    }

    .platform-hes_item {
      display: block;
      margin-bottom: 55px;

      .img {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
        margin-right: 0;
      }

      .content {
        width: 100%;
      }
    }
  }
}

@media (max-width: 780px) {
  .platform-hes {
    padding: 60px 0 0;

    .platform-hes_item {
      width: 50%;
      display: flex;
       align-items: center;
      .img {
       width: 20%;
        margin-right: 20px;
      }

      .content {
        width: 80%;
        max-width: none;
        flex-grow: 1;
      }
    }
  }
}

@media (max-width: 580px) {
  .platform-hes {
    padding: 50px 0 30px;
    .platform-hes_item {
      width: 100%;
      margin-bottom: 10px;
      .img{
        max-width: 50px;
        img{
          width: 100%;
        }
      }
    }
  }
}
