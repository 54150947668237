@import "variables.scss";

html, .height-100{
  height: 100%;
}

.height-100{
  .container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    main{
      background-image: url(../img/solutions_bg.jpg);
      background-size: cover;
      flex: 1;
      display: flex;
      align-items: center;
      margin-bottom: -1px;
    }
  }
}


.err-404{
  font-family: 'Lato';
  max-width: 360px;
  margin: 40px auto 90px;
  text-align: center;
  color:#fff;
  &_img{
    padding-bottom: 5px;
    .err-404_img_wr{
      position: relative;
      right: 20px;
    }
    .font-18{
      font-size: 18px;
      position: relative;
      right: -40px;
    }
    .font-135{
      font-size: 135px;
      font-family: 'Lato';
      font-weight: 300;
    }
  }
  &_title{
    font-size: 25px;
    line-height: 32px;
    padding-top: 35px;
    border-top: 1px solid #c4c4c4;
  }
  &_text{
    font-size: 14px;
    line-height: 24px;
    font-family: 'Lato';
    margin: 25px 0 35px;
  }
  .btn{
    width: 200px;
    margin: 0 auto;
  }
}