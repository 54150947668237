@import 'variables';

.solutions{
  padding-top: 85px;
  height: 822px;
  background-image: url("../img/solutions_bg.jpg");
  background-size: auto 100%;
  h2{
    color:#fff;
    margin-bottom: 55px;
  }
  .fx-container{
    justify-content: space-between;
  }
  .card{
    width: 403px;
    height: 505px;
    background-color: #fff;
    padding: 30px 50px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logo{
      margin-bottom: 15px;
      height: 40px;
      display: flex;
      align-items: flex-end;
    }
    .line{
     width: 50px;
      height: 2px;
      background-color: $green-color;
      margin-bottom: 10px;
    }
    .title{
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 23px;
      line-height: 36px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-family: Lato,  'sans serif';
        font-weight: normal;
      }
    }
    a{
      color: $blue-color;
      text-decoration: underline;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 12px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-family: Lato,  'sans serif';
        font-weight: normal;
      }
    }

  }
}

@media (max-width: 1366px) {
  .solutions{
    height: auto;
    padding: 85px 0;
    .card{
      width: 31%;
      height: auto;
      min-height: 505px;
      padding: 35px;
      .title{
        font-size: 18px;
        line-height: 29px;
      }
    }
  }
}

@media (max-width: 1120px) {
  .solutions{
    padding: 65px 0;
    .card{
      width: 100%;
      margin-bottom: 29px;
      min-height: auto;
      padding: 35px 75px;
      .line{
        display: none;
      }
      .text{
        margin:13px 0;
      }
    }
    }
}

@media (max-width: 992px) {
  .solutions{
    .card{
      padding: 35px 55px;
    }
  }
}

@media (max-width: 780px) {
  .solutions{
    padding: 45px 0 35px;
    h2{
      margin-bottom: 40px;
    }
    .card{
      padding: 35px 35px;
    }
  }
}
