@import 'variables';

footer{
 // height: 100px;
  background-color: $footer-color;
  padding: 35px 0 25px;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 0.8;
  .fx-container{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .logos{

    margin-bottom: 7px;
    display: flex;
    align-items: flex-end;
    max-width: 380px;
    a{
      margin-right: 12px;
      img{
        width: 100%;
      }
    }
  }
}

@media (max-width: 700px) {
  footer{
    .fx-container{
      flex-wrap: wrap;
    }
    .logos{
      width: 100%;
      margin-left: 0;
    }
  }
}