@import 'variables';

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.ttf');
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-size-adjust: none;
}

body{
  font-family: Lato, 'sans serif';
  color: #000;
}

h1, h2, h3, h4, h5{
  font-family: Montserrat,  'sans serif';
  color:$black-color;
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  h1, h2, h3, h4, h5{
    font-family: Lato,  'sans serif';
    font-weight: normal;
  }
}

h1{
  font-size: 44px;
  line-height: 59px;
}
h2{
  font-size: 37px;
  line-height: 57px;
}
ul{
  list-style: none;
}
a{
  text-decoration: none;
}
.fx-container{
  display: flex;
  flex-wrap: wrap;
}
.container{
  max-width: 1920px;
  margin:0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  main{
    flex: 1;
  }
}

.wrapper{
  max-width: 1256px ;
  margin: 0 auto;
}

.yellow{
  color: $yellow-color;
}

.green{
  color:$green-color;
}
.text-16{
  font-size: 16px;
  line-height: 29px;
}
.text-18{
  font-family: Montserrat,'sans serif';
  font-weight: 500;
  font-size: 18px;
  line-height: 44px;
  color: #2E2C2D;
  opacity: 0.8;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    font-family: Lato,  'sans serif';
    font-weight: normal;
  }
}
.hidden{
  display: none;
}
.overflow{
  overflow: hidden;
}
.incrImg{
  cursor: pointer;
}
.overlay{
  position: fixed;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.nowrap{
  white-space: nowrap;
}

.btn {
  text-align: center;
  height: 43px;
   line-height: 40px;
  text-decoration: none;
  border: 2px solid $blue-color;
  background: transparent;
  border-radius: 50px;
  color: $blue-color;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  font-family: 'Montserrat';
  transition: background-color 0.2s, color 0.2s, opacity 0.2s;
  white-space: nowrap;
  letter-spacing: 1px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    font-family: Lato,  'sans serif';
    font-weight: normal;
  }
  span{
    vertical-align: middle;
    display: flex;
    align-items: center;
    img{
      margin-left: 20px;
    }
  }
  &:hover{
    opacity: 0.8;
    background-color: $blue-color;
    color:#fff;
  }
  &:focus, &:active {
    outline: none;
  }
 &.btn-blue{
    width: auto;
   height: 50px;
   padding: 0 35px;
   background-color: $blue-color;
   color:#fff;

 }
  &.btn-green{
  //  width: auto;
    height: 50px;
    padding: 0 35px;
    background-color: $green-color;
    color:#fff;
    border-color: $green-color;
  }
  &.btn-blue-border{
    width: auto;
    height: 50px;
    padding: 0 32px;
  }
}

.list{
  padding-left: 28px;
  li{
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 25px;
    position: relative;
  }
}

@media (max-width: 1366px) {
  .wrapper{
    padding: 0 50px;
  }
  h1{
    font-size: 35px;
    line-height: 47px;
  }
  h2{
    font-size: 32px;
  }
  .text-16{
    font-size: 15px;
    line-height: 27px;
  }
}

@media (max-width: 1170px) {
  h1{
    font-size: 32px;
    line-height: 43px;
  }
  h2{
    font-size: 26px;
    line-height: 41px;
  }
  .text-16, .list li{
    font-size: 14px;
    line-height: 25px;
  }
  .text-18{
    font-size: 16px;
    line-height: 1.5;
  }
  .list{
    li{
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 992px) {
  h1{
    font-size: 26px;
    line-height: 35px;
  }
  h2{
    font-size: 24px;
    line-height: 38px;
  }
  .btn {
    &.btn-blue{
      height: 50px;
    //  padding: 0 40px;
    }
  }
}

@media (max-width: 768px) {
  .wrapper{
   padding: 0 30px;
  }

}

@media (max-width: 580px) {
  .list{
    li{
      margin-bottom: 10px;
    }
  }
}
