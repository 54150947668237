@import 'variables';
.whot-is-agro{
  padding: 108px 0 70px;
    .left-block{
      width: 50%;
      position: relative;
      .tablet{
        cursor: pointer;
        img{
          width: 100%;
        }
      }
      img{
        width: 100%;
        position: relative;
        z-index: 50;
      }
    }
    .right-block{
      width: 50%;
      display: flex;
      align-items: center;
      padding-left: 70px;
     .text{
       margin: 39px 0 20px;
      }

    }
    .images{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width:100%;
    }
  .images2{
    justify-content: flex-start;
    align-items: center;
    display: none;
    max-width: 650px;
  }
    .deskription{
      max-width: 350px;
      flex: 1;
      margin-left: 15px;
      margin-top: -20px;
      .title{
        font-family: Montserrat;
        font-weight: 500;
        font-size: 18px;
        line-height: normal;
        color: #000000;
        padding-bottom: 5px;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          font-family: Lato,  'sans serif';
          font-weight: normal;
        }
      }
      .logos{
        display: flex;
        align-items: flex-end;
        img{
          width: 100%;
        }
        a{
          margin-right: 16px;
          display: block;
          height: 30px;
        }
        svg{
          fill: #c1c0c0;
          width: 100%;
        }
        .oneSoil{
          background-image: url("../img/oneSoil_logo.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: bottom left;
          &:hover{
            background-image: url("../img/logo-onesoil.svg");
          }
        }
        .hes{
          background-image: url("../img/hes_logo.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: bottom left;
          &:hover{
            background-image: url("../img/logo-hes.svg");
          }
        }
        .ginimachine{
          background-image: url("../img/ginimachine_logo.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: bottom left;
          &:hover{
            background-image: url("../img/logo-gini.svg");
          }
        }
      }

  }
}

@media (max-width: 1366px) {
  .whot-is-agro{
    .left-block{
      .play{
        top:37%;
      }
    }
    .right-block{
      .text{
        margin: 20px 0 25px;
      }
    }
    .deskription{
      .title{
        font-size: 16px;
      }
    }
  }

}

@media (max-width: 1170px) {
  .whot-is-agro{
    padding: 85px 0 20px;
    .images{
      display: none;
    }
    .images2{
     display: flex;
    }

  }

}

@media (max-width: 992px) {
  .whot-is-agro{
    .fx-container{
      align-items: flex-start;
    }
    .left-block{
      order: 2;
      width: 60%;
      img{
       // width: 105%;
        position: relative;
        left:auto;
        right: -20px;
      }
    }
    .right-block{
      order: 1;
      width: 40%;
      padding-left: 0;
      .text{
        padding-right: 50px;
      }
    }
  }
}

@media (max-width: 780px) {

  .whot-is-agro{
    padding: 50px 0 20px;
    .fx-container{
      flex-wrap: wrap;
    }
    .left-block{
      width: 100%;
      img{
        width:100%;
        left: 0;
        &.en{
          margin-bottom: 30px;
        }
      }
    }
    .right-block{
      width: 100%;
      .text{
        padding-right: 0;
        margin-bottom: 25px;
      }
    }
    .images2{
      margin: -30px 0 -20px;
      text-align: center;
      .icon{
        display: none;

      }
      .deskription{
       margin: 30px auto 50px;
      }
    }
  }

}

