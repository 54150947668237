.big-img_overlay {
  position: fixed;
  z-index: 400;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  .image{
    text-transform: uppercase;
    height: auto;
    max-height: 100%;
    width: 100%;
    max-width: 1100px;
    overflow-y: auto;

   background-color: #fff;

    img{
      margin: -10px 0 ;
    }
  }
}


@media (max-width: 650px) {
  .big-img_overlay {
    padding: 0;
  }
}


.zoomInImg {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
  50% {
    opacity: 1;
  }
}
