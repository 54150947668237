@import 'variables';

@keyframes fixMenu {
  from {
    top: -105px;
  }
  to {
    top:0;
  }
}

header {
  height: 100px;
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  &.fixed{
    position: fixed;
    height: 70px;
    animation: fixMenu 0.4s ease;
    .header_logo {
      font-size: 20px;

      img {
        width: 50px;
      }
    }
  }
  .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .header_logo {
    svg {
      width: 72px;
      height: 64px;
    }

    display: flex;
    align-items: center;
    height: 100%;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 29px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      font-family: Lato, 'sans serif';
      font-weight: normal;
    }
  }

  .header_nav {
    display: flex;

    .nav {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      li {
        margin-right: 40px;

        a {
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          color: $black-color;
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            font-family: Lato, 'sans serif';
            font-weight: normal;
          }

          &:hover {
            color: $blue-color;
          }
        }
      }
    }
  }

}


.header_action {
  display: flex;
  align-items: center;
  .lang-link{
    margin-left: 30px;
    a{
      color: $green-color;
    }
  }
}


.burger {
  display: none;
  margin-left: 30px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: url('../img/burger.png') no-repeat;
  background-position: center center;
  background-size: 30px;

  &.cross {
    background: url('../img/tag-close.png') no-repeat;
    background-position: center left;
    background-size: 25px;
  }
}


.mobile-menu {
  position: fixed;
  z-index: 400;
  top: 70px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-top: 1px solid #dbe2ea;

  .mobile-menu_main {
    text-transform: uppercase;
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    text-align: center;
    ul {
      li {
        border-bottom: 1px solid #dbe2ea;

        a {
          font-size: 16px;
          line-height: 60px;
          color: $black-color;
        }
      }
    }


    .btn{
      width: 220px;
    }

  }

  .mobile-menu_footer {
    .contact-phone {
      line-height: 80px;
    }

    .btn {
      display: none;
      background-color: $blue-color;
      color: #fff;
      margin-top: 30px;
    }
    .lang-link-mobile{
      display: none;
    }

  }


}

.pT-105{
  padding-top: 105px;
}


@media (max-width: 1170px) {
  header {
    .header_nav {
      display: flex;

      .nav {
        li {
          margin-right: 30px;
        }
      }
    }
  }
}

@media (max-width: 1130px) {
  .pT-105{
    padding-top: 70px;
  }
  header {
    height: 70px;

    .header_logo {
      font-size: 20px;

      img {
        width: 50px;
      }
    }

    .header_nav {
      .nav{
        display: none;
      }
    }
  }
  .burger {
    display: block;
  }
}

@media (max-width: 530px) {
  header {
    .demo-btn , .lang-link {
      display: none;
    }
  }
  .mobile-menu{
    .mobile-menu_footer {
      .mobile-btn{
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      .lang-link-mobile{
        display: block;
        margin-top: 25px;
        a{
          color:$green-color;
        }
      }
    }
  }
}

