@import 'variables';


.contact-form {

  .center-wrapper {
    padding: 70px 90px 50px;
  }

  .form-title {
    margin-bottom: 40px;
  }

  form {
    padding-top: 25px;

    .checkbox-field {
      margin: 0 0 20px;
    }

    .fx-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .form-group {
      width: 47%;
      @media (max-width: 750px) {
        width: 100%;
      }
    }

    .btn {
      min-width: 200px;
    }
  }

  form .checkbox-field {

    height: 50px;
    margin: 40px 0 16px;

    .checkbox-label {
      width: calc(100% - 40px);
      display: inline-block;
      vertical-align: bottom;
      color: $placeholder-color;
      &.isChecked{
        color: $dark-text-color;
      }
    }
  }

  @media (max-width: 1240px) {
    .wrapper {
      padding: 0 30px;
    }
  }

  @media (max-width: 1000px) {
    .center-wrapper {
      padding: 40px 0;
    }
  }


  .gray {
    color: #bababa;
  }

  form {
    width: 100%;
  }

  .form-fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form-group {
    width: 100%;
    height: 55px;
    margin-bottom: 20px;
    padding-bottom: 17px;
    position: relative;

    &.w-100 {
      width: 100%;
    }

    input, textarea, select {
      width: 100%;
      border: none;
      border-bottom: 1px solid $light-text-color;
      padding-bottom: 8px;
      color: $dark-text-color;
      font-size: 17px;
      font-family: Montserrat, 'sans serif';
      &:focus {
        outline: none;
        border-bottom: 1px solid $dark-text-color;
      }
    }

    &.has-success{
      input, textarea, select{
        border-bottom: 1px solid $green-color;
      }
    }

    textarea {
      resize: vertical;
      height: auto !important;
      min-height: 30px;
      max-height: 100px;

    }

    input:disabled {
      color: rgb(84, 84, 84);
      cursor: default;
      background-color: rgba(235, 235, 228, 0.2);
    }

    ::placeholder {
      color: $light-text-color;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: $light-text-color !important;
    }

    &.has-success {
      input, textarea, select {
        border-bottom: 1px solid $green-color;
      }
    }

    &.has-error {
      input, textarea, select {
        color: $error-color;
        border-bottom: 1px solid $error-color;
      }
    }

    &.has-focus {
      input, textarea, select {
        border-bottom: 1px solid $dark-text-color;
      }
    }

    .error {
      font-size: 12px;
      color: $error-color;
    }

  }

  .help-block {
    position: absolute;
    left: 0;
    bottom: 9px;
    color: $error-color;;
    font-size: 13px;
  }

  .form-group-textarea{
    .help-block{
      bottom: 4px;
    }

  }

  .checkbox-field {
    .help-block {
      bottom: -10px;

      &.hidden {
        display: none;
      }
    }
  }

  #reason {
    width: 48%;
  }

  .required-info {
    font-size: 12px;
    margin-top: -10px;
  }

  input[type="checkbox"] {
    display: none;
    position: relative;
  }

  input[type="checkbox"] + label:before {
    border: 1px solid #cccccc;
    background: #fff;
    content: "";
    display: inline-block;
    font: 16px/1em serif;
    height: 20px;
    margin: 0 .80em 0 0;
    padding: 0;
    vertical-align: top;
    width: 20px;
  }

  input[type="checkbox"]:checked + label:before {
    border: 1px solid $green-color;
    background-color: $green-color;
    color: #fff;
    content: "";
    background-image: url("../img/checkmark.png");
    background-size: cover;
    text-align: center;
    font-weight: bold;
    line-height: 20px;
  }

  input[type="checkbox"]:checked + label:after {
    font-weight: bold;
  }

  @keyframes flipy {
    from {
      top: 0;
    }
    to {
      top: -25px;
    }
  }

  label.hidden {
    display: none;

    &.vsb {
      display: block;
      position: absolute;
      left: 0;
      top: -25px;
      font-size: 13px;
      color: $light-text-color;
      animation: flipy 0.1s linear;
      font-family: Montserrat;
    }
  }

  .has-error {
    label {
      &.vsb {
        color: $error-color;
      }
    }
  }
  .required-info{
    font-size: 12px;
    margin-top: -10px;
  }
  .actions{
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .question-image{
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      height: 14px;
      svg{
        fill:#a5abb5;
      }
      &:hover, &.active{
        cursor: pointer;
        svg{
          fill:$green-color;
        }
      }
    }
    &_sendForm{
      margin-right: 45px;
    }
  }

  input[type=file]{
    display: none;
  }
  .addFile-label{
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-weight: 700;
    letter-spacing: 1px;
    white-space: nowrap;
    svg{
      fill: $green-color;
    }
    span{
      margin-right: 6px;
      font-family: Montserrat, 'sans serif';
      &:hover{
        color:$green-color;
      }
    }
    span, img{
      vertical-align: middle;
    }

  }

  .question-image{
    display: inline-block;
  }

  .file-name{
    position: absolute;
    top:30px;
    left: 0;
    display: none;
    font-size: 12px;
    height: 15px;
    .text-wrapper{
      overflow: hidden;
      max-width: 150px;
      display: inline-block;
    }
    .text{
      overflow: hidden;
      width: 100%;
      text-overflow:ellipsis;
      display: inline-block;
    }
    span, img{
      vertical-align: top;
      margin-right: 6px;
    }
    .file-icon{
      svg{
        fill:#00A4C4;
      }
    }
    .delete{
      display: inline-block;
      cursor: pointer;
      svg{
        fill:#CECECE;
        width: 12px;
        height: 12px;
      }
      &:hover{
        svg{
          fill: $green-color;
        }
      }
    }
  }
}

.ations_addFile{
  position: relative;
}

.file-requirements{
  width: 315px;
  font-size: 12px;
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top:-30px;
  &.hidden{
   display: none;
  }
}

.form-group_country{
  position: relative;
  &:after {
    position: absolute;
    content: url('../img/drop-arrow.svg');
    top: 3px;
    right: 0;
    height: 7px;
    width: 7px;
  }
}

#cantries{
  position: absolute;
  top: 30px;
  background-color: #fff;
  width: 100%;
  height: auto;
  max-height: 300px;
  overflow: auto;
  box-shadow: $box-shadow;
  z-index: 50;
  div{
    cursor: pointer;
    font-size: 11px;
    text-transform: uppercase;
    padding: 15px 30px;
    &:hover, &.active{
      background-color: $list-bg;
    }
  }
}

@media (max-width: 520px) {
  .contact-form {
    .actions{
      display: block;
      #orderDemoSubmit{
        margin-bottom: 30px;
      }
    }
  }
}



