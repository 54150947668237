@import 'variables';

.credit-scoring{
   padding: 85px 0;
   height: 607px;
   background-image: url("../img/credit-scoring_bg.jpg");
   background-size: auto 100%;
   color:#fff;
   h2, .text-16{
      color:#fff;
   }
   .wrapper{
      display: flex;
   }
   .left-block{
      width: 50%;
      .image{
         position: relative;
         margin-top: 40px;
         box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.15), 0px 0px 10px rgba(0, 0, 0, 0.03);
         img{
            width: 100%;
         }
      }
   }
   .right-block{
      padding-top: 20px;
      width: 50%;
      margin-left: 144px;
      max-width: 430px;
   .list{
      margin-top: 35px;
      li:before{
         content: '';
         width: 15px;
         height: 15px;
         position: absolute;
         background-image: url("../img/yellow-marker.svg");
         background-repeat: no-repeat;
         top: 8px;
         left:-30px;
      }
   }
   }
}

@media (max-width: 1366px) {
   .credit-scoring{
      .left-block {
         .image {
            margin-top: 50px;
         }
      }
   }
}

@media (max-width: 1170px) {
   .credit-scoring{
      padding: 60px 0;
      height: 489px;
      .right-block{
         padding-top: 10px;
         margin-left: 110px;

      }
   }
}

@media (max-width: 992px) {
   .credit-scoring{
      height: auto;
      .wrapper{
         display: block;
      }
      .left-block{
          width: 100%;
         h2{
            display: flex;
            flex-wrap: wrap;
            p{
               padding-right: 8px;
            }
         }
         .image {
            margin:40px 0;
            width: 100%;
         }
       }
      .right-block{
         width: 100%;
         max-width: 100%;
         margin-left: 0;
        display: flex;
         .text-16{
            width: 50%;
            padding-right: 70px;
         }
         .list{
            width: 50%;
            margin-top: 0;
         }
      }
   }
}

@media (max-width: 780px) {
   .credit-scoring{
      padding: 50px 0 30px;
      .right-block{
         display: block;
         .text-16{
            width: 100%;
            padding-right: 0;
            margin-bottom: 30px;
         }
         .list{
            width: 100%;
         }
      }
   }

}

@media (max-width: 486px) {
   .credit-scoring{
      .left-block {
         .image {
            margin: 30px 0 20px;
         }
      }
   }
}