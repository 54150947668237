@import "variables.scss";

.modal-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  &.hidden{
    display: none;
  }
  .modal{
    padding: 60px 60px 40px;
    background-color: #fff;
    text-align: center;
    position: relative;
    color:$blue-color;
    font-family: Montserrat, 'sans serif';
    h3{
      font-size: 1.875rem;
      color: $blue-color;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p{
      font-size: 1.0625rem;
      line-height: 25px;
      font-weight: 300;
      margin-bottom: 30px;
    }
     .cross{
       cursor: pointer;
       position: absolute;
       top: 11px;
       right: 12px;
       svg{
         fill: #CECECE;
         width: 14px;
         height: 14px;
       }
     }
  }
  .btn{
    margin-right: auto;
    margin-left: auto;
    min-width: 150px;
  }
}
